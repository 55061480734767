import {
    Box,
    Container, FormControlLabel, FormGroup, FormHelperText, Grid, Typography,
} from '@mui/material';
import {
    Title, Fingerprint, MailOutline, PhoneOutlined,
} from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { AxiosResponse } from 'axios';
import { LoggedInUser } from '../../../types/auth.types';
import Button from '../foundation/Button/Button';
import { ShadowBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';
import UserNotification, { UserNotificationProps } from '../components/UserNotification';
import { Flexbox } from '../foundation/Flex';
import {
    CompanyAutocomplete, GeoAutocomplete, LocationOption, TagsSearchAutocomplete,
} from '../components/AutocompleteRemote';
import TextArea from '../foundation/TextArea';
import Checkbox from '../foundation/Checkbox';
import { TagViewModel } from '../../../types/tags.types';
import RemovableTag, { AddableTag } from '../foundation/RemovableTag';
import { createNewJob, editJob, getTags } from '../../api/job.api';
import { updateQueryStringParameter } from '../../utils/urlUtils';
import Anchor from '../foundation/Anchor';
import DateFormat from '../foundation/DateFormat';
import Select from '../foundation/Select';
import { CompanyViewModel } from '../../../mappers/companyMapper';
import { JobViewModel } from '../../../types/jobs.types';

export type UserPostJobPageProps = {
    url: string
    user?: LoggedInUser
    job?: JobViewModel
    safeid?: string
    locationAbroadChecked?: boolean,
    locationAllStateChecked?: boolean
    numberOfJobsRelatedToUnkownUser?: number
    totalPromotions?: number
    totalAllowedJobs?: number
}

interface IFormPostJob {
    title: string
    description: string
    phone: string
    requirement?: string
    locationAllStateChecked?: boolean
    locationAbroadChecked?: boolean
    email?: string
    employerJobid: string
    jobPeriod: number
    promotion?: boolean
}

const BoxBorder = styled.div`
    border: 1px solid #bfbfc1;
    padding: 0.5rem 1rem;
    border-radius: 10px;
`;

const INTERNAL_JOB_PERIOD = 30;
const DEFAULT_JOB_PERIOD = 1; // * 4 weeks

const UserPostJobPage = (props: UserPostJobPageProps) => {
    const editMode = !!props.job;
    const [notification, setNotification] = useState<UserNotificationProps>(null);
    const [jobCreated, setJobCreated] = useState<boolean>(false);
    const [jobTags, setJobTags] = useState<TagViewModel[]>(props.job?.tags || []);
    const [recommendedTags, setRecommendedTags] = useState<TagViewModel[]>([]);
    const defaultLocationAbroadChecked = props.job?.formatted_address === 'חו"ל';
    const defaultLocationAllStateChecked = !props.safeid && props.job?.formatted_address === 'כל הארץ';
    const {
        register, handleSubmit, errors, watch, setValue,
    } = useForm<IFormPostJob>({
        defaultValues: {
            locationAbroadChecked: defaultLocationAbroadChecked,
            locationAllStateChecked: defaultLocationAllStateChecked,
            promotion: props.totalPromotions >= DEFAULT_JOB_PERIOD,
            jobPeriod: DEFAULT_JOB_PERIOD,
        },
    });

    const allowAdvancedFeatures = props.totalPromotions > 0;

    const fetchRecommendedTags = async () => {
        const text = `${watch('title')} ${watch('description')} ${watch('requirement')}`;
        const tagsRes = await getTags({ text });
        const currentTagIds = jobTags.map(t => t.tagid);

        if (currentTagIds && currentTagIds.length > 0) {
            setRecommendedTags(tagsRes.data?.filter(tag => currentTagIds.indexOf(tag.tagid) === -1));
        } else {
            setRecommendedTags(tagsRes.data);
        }
    };

    const [company, setCompany] = useState<Partial<CompanyViewModel>>(props.job?.company);
    const companyChange = (ev, val) => { setCompany(val); };
    const companyInputChange = (ev, val) => { setCompany({ name: val }); };

    const [location, setLocation] = useState<Partial<LocationOption> & { formatted_address: string }>(props.job ? {
        latitude: props.job.latitude,
        longitude: props.job.longitude,
        formatted_address: props.job.formatted_address,
    } as LocationOption : null);
    const locationChange = (ev, val) => { setLocation(val); };
    const locationInputChange = (ev, newVal) => { setLocation({ formatted_address: newVal }); };

    const onSubmit = async (data: IFormPostJob) => {
        try {
            let locationData = location;
            if (!jobTags?.length || jobTags.length > 8) {
                setNotification({
                    severity: 'error',
                    message: 'יש להזין מינימום תגית אחת וניתן להזין עד שמונה תגיות',
                    id: Math.random(),
                });
                return;
            }

            if (!data.locationAbroadChecked && !data.locationAllStateChecked && !location?.latitude) {
                setNotification({
                    severity: 'error',
                    message: 'חובה להזין מיקום משרה ולבחור מהרשימה או לציין עבודה מהבית \\ בחוץ לארץ, מומלץ להזין מיקום מדויק.',
                    id: Math.random(),
                });
                return;
            }

            if (data.locationAbroadChecked) {
                locationData = {
                    formatted_address: 'חו"ל',
                    latitude: null,
                    longitude: null,
                };
            } else if (data.locationAllStateChecked) {
                locationData = {
                    formatted_address: 'כל הארץ',
                    latitude: null,
                    longitude: null,
                };
            }

            let jobRes: AxiosResponse<JobViewModel>;

            if (editMode) {
                jobRes = await editJob({ jobid: props.job.jobid }, { safeid: props.safeid }, {
                    jobid: props.job.jobid,
                    ...locationData,
                    ...data,
                    company: company?.name,
                    tags: jobTags.map(tag => tag.tagid),
                }, true);
                setNotification({
                    id: Math.random(),
                    children: <><Typography>משרת העבודה נשמרה בהצלחה ותעודכן באתר בדקות הקרובות</Typography></>,
                    severity: 'success',
                });
            } else {
                jobRes = await createNewJob({
                    ...locationData,
                    ...data,
                    company: company?.name,
                    jobPeriod: (data.jobPeriod || 1) * INTERNAL_JOB_PERIOD,
                    // 0 is for internal jobs. 1 for promoted. undefined for external jobs
                    promotion: data.promotion ? 1 : 0,
                    tags: jobTags.map(tag => tag.tagid),
                }, true);
                setJobCreated(true);
                setNotification({
                    id: Math.random(),
                    children: <>
                        <Typography>משרת העבודה נשלחה בהצלחה ותפורסם באתר בדקות הקרובות!</Typography>
                        <Anchor text="עבור לעריכת המשרה" href={`/user/editJob/${jobRes.data.jobid}`} />
                        &nbsp;|&nbsp;
                        <Anchor text="עבור לניהול המשרות" href={'/user/postedJobs'} />
                    </>,
                    severity: 'success',
                });
            }
        } catch (error) {
            if (error?.response?.status === 409) {
                setNotification({
                    id: Math.random(),
                    children: (<div>
                        <Typography>לא ניתן לשמור את המשרה מכיוון שמשרה זהה קיימת כבר.</Typography>
                        <Anchor href={`/user/editJob/${error.response.data?.id}`} text="קישור למשרה הקיימת" blank />
                    </div>),
                    severity: 'error',
                });
            } else {
                setNotification({
                    id: Math.random(),
                    message: error.response?.data || error.message,
                    severity: 'error',
                });
            }
        }
    };

    const filteredRecommnededTags = recommendedTags.filter(rTag => !jobTags.find(jTag => jTag.tagid === rTag.tagid));

    const jobPeriod = watch('jobPeriod', 1);
    const jobPromotionAllowed = props.totalPromotions >= jobPeriod;
    const promotion = watch('promotion', jobPromotionAllowed);

    const totalPeriodsAllowedForJob = promotion ? props.totalPromotions : props.totalAllowedJobs;

    React.useEffect(() => {
        if (promotion && jobPeriod > props.totalPromotions) setValue('promotion', false);
    }, [jobPeriod]);

    const renderChooseJobPromotion = () => {
        const periodOptions = [];
        const endDate = new Date(Date.now() + 86400000 * jobPeriod * INTERNAL_JOB_PERIOD);

        for (let i = 0; i < totalPeriodsAllowedForJob; i += 1) {
            periodOptions.push({ text: `${i + 1} x חודש`, value: i + 1 });
        }

        return <ShadowBGContainer>
            <Typography color="secondary" variant="h6" fontWeight="bold">
            מסלול פרסום
            </Typography>
            <CustomBR />
            <>
                <>
                    <Select
                        name="jobPeriod"
                        id="jobPeriod"
                        inputRef={register({ required: true })}
                        size="small"
                        defaultValue={1}
                        // disabled={periodOptions.length === 1}
                        disabled
                        options={periodOptions}
                    />
                    <FormHelperText>משך תקופת פרסום המשרה</FormHelperText>
                </>
                <CustomBR h={10} />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            checked={promotion}
                            disabled={!props.totalAllowedJobs || !props.totalPromotions}
                            onChange={ev => setValue('promotion', ev.target.checked)}
                            // defaultChecked={jobPromotionAllowed}
                            inputRef={register()}
                            name="promotion"
                        />}
                        label="משרה מקודמת"
                    />
                </FormGroup><CustomBR h={10} /></>
            <div>
                <Box display="flex">
                    <Typography>המשרה תפורסם {jobPromotionAllowed && promotion && <b>ותקודם</b>} </Typography>&nbsp;
                    <Typography>עד תאריך: </Typography>&nbsp;
                    <DateFormat showTime date={endDate} />
                </Box>
            </div>
            {totalPeriodsAllowedForJob > 1 && <>{promotion ? <Typography variant="caption">
                בחשבונך קיימים כעת <b>{props.totalPromotions}</b> מודעות דרושים מקודמות,
                לאחר פרסום המשרה יישארו <b>{props.totalPromotions - jobPeriod}</b> מודעות דרושים מקודמות
            </Typography> : <Typography variant="caption">
                בחשבונך קיימים כעת <b>{props.totalAllowedJobs}</b> מודעות דרושים.
                לאחר פרסום המשרה יישארו <b>{props.totalAllowedJobs - jobPeriod}</b>.
            </Typography>}</>}
        </ShadowBGContainer>;
    };

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle={editMode ? 'Mploy - עריכת פרטי המשרה' : 'Mploy - פרסום משרה חדשה'}>
        <Container>
            <CustomBR />
            {editMode && !props.user && props.numberOfJobsRelatedToUnkownUser && <>
                <UserNotification
                    message={`מצאנו עוד ${props.numberOfJobsRelatedToUnkownUser} משרות המשויכות לכתובת המייל: ${props.job.email}. `}
                    severity="info"
                    id="more-jobs"
                >
                    <div>
                        <CustomBR h={10} />
                        <Button
                            size="small"
                            onClick={() => {
                                window.history.pushState({}, '', updateQueryStringParameter('redirect', '/user/postedJobs'));
                                window.modals?.toggleRegisterModal();
                            }}
                            variant="outlined">הירשם וקבל שליטה על כל המשרות שלך</Button>
                    </div>
                </UserNotification>
                <CustomBR />
            </>}
            {notification && <><UserNotification {...notification} /><CustomBR /></>}
            {props.user && <Flexbox flexDirection="row-reverse">
                <Button onClick={() => window.location.assign('/user/postedJobs')} color="primary">ניהול המשרות שלי</Button>
                {editMode && <>&nbsp;&nbsp;<Button onClick={() => window.location.assign('/user/postJobs')} variant="outlined">פרסום משרה נוספת</Button></>}
            </Flexbox>}
            <CustomBR h={10} />
            {editMode && <>
                <Typography display="inline">קישור  למשרה באתר:&nbsp;</Typography>
                <Anchor text={props.job.title} blank href={`/job/details/${props.job.jobid}`} />
            </>}
            {!jobCreated && <><CustomBR h={10} />
                <ShadowBGContainer>
                    <TextField
                        defaultValue={props.job?.title}
                        required
                        inputRef={register({ required: true })}
                        name="title"
                        id="title"
                        label="כותרת המשרה"
                        type="text"
                        fullWidth
                        error={!!errors.title}
                        helperText={errors.title && 'יש להזין כותרת'}
                        InputProps={{ endAdornment: <Title /> }}
                    />
                    <CustomBR />
                    <GeoAutocomplete
                        required
                        onChange={locationChange}
                        onInputChange={locationInputChange}
                        defaultValue={location?.formatted_address}
                        disabled={watch('locationAllStateChecked') || watch('locationAbroadChecked')}
                        helperText="מיקום המשרה, מומלץ לרשום מיקום מדויק"
                    />
                    <CustomBR />
                    <Grid spacing={2} container>
                        <Grid item sm={6} xs={12}>
                            <BoxBorder>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            defaultChecked={defaultLocationAllStateChecked}
                                            disabled={watch('locationAbroadChecked')}
                                            inputRef={register()}
                                            name="locationAllStateChecked"
                                        />}
                                        label="המשרה בכל הארץ \ מהבית"
                                    />
                                </FormGroup>
                            </BoxBorder>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <BoxBorder>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            defaultChecked={defaultLocationAbroadChecked}
                                            disabled={watch('locationAllStateChecked')}
                                            inputRef={register()}
                                            name="locationAbroadChecked"
                                        />}
                                        label="המשרה בחוץ לארץ"
                                    />
                                </FormGroup>
                            </BoxBorder>
                        </Grid>
                    </Grid>
                    <CustomBR />
                    <TextArea
                        defaultValue={props.job?.description}
                        inputRef={register({ required: true, minLength: 10, maxLength: 1000 })}
                        required
                        onBlur={fetchRecommendedTags}
                        label="תיאור המשרה"
                        name="description"
                        id="description"
                        helperText={errors.description && 'יש להזין תיאור משרה בין 10 ל 1000 תווים.'}
                        fullWidth
                        rows={5}
                        maxLength={1000}
                    />
                    <CustomBR />
                    <TextArea
                        defaultValue={props.job?.requirement}
                        inputRef={register({ maxLength: 600 })}
                        label="דרישות המשרה"
                        onBlur={fetchRecommendedTags}
                        name="requirement"
                        id="requirement"
                        helperText={errors.requirement && 'מקסימום 600 תווים.'}
                        fullWidth
                        rows={5}
                        maxLength={600}
                    />
                </ShadowBGContainer>
                <CustomBR />
                <ShadowBGContainer>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                defaultValue={props.job?.email || ''}
                                inputRef={register()}
                                name="email"
                                id="email"
                                label={`אימייל לקבל פניות${!allowAdvancedFeatures ? ' (זמין במשרות פרמיום)' : ''}`}
                                type="email"
                                fullWidth
                                disabled={!allowAdvancedFeatures}
                                error={!!errors.email}
                                helperText={props.user && !props.job?.email && `ברירת מחדל: ${props.user.email}`}
                                InputProps={{ endAdornment: <MailOutline /> }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                defaultValue={props.job?.phone}
                                inputRef={register()}
                                name="phone"
                                id="phone"
                                label="טלפון ליצירת קשר"
                                type="number"
                                fullWidth
                                error={!!errors.phone}
                                InputProps={{ endAdornment: <PhoneOutlined /> }}
                            />
                        </Grid>
                    </Grid>
                    <CustomBR />
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <CompanyAutocomplete
                                onInputChange={companyInputChange}
                                defaultValue={company?.name}
                                onChange={companyChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                defaultValue={props.job?.employerJobid || ''}
                                inputRef={register()}
                                name="employerJobid"
                                id="employerJobid"
                                label={`מספר מזהה משרה${!allowAdvancedFeatures ? ' (זמין במשרות פרמיום)' : ''}`}
                                disabled={!allowAdvancedFeatures}
                                helperText="מספר זה יצורף לכותרת האימייל ממועמדים"
                                type="text"
                                fullWidth
                                error={!!errors.employerJobid}
                                InputProps={{ endAdornment: <Fingerprint /> }}
                            />
                        </Grid>
                    </Grid>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                defaultChecked={editMode ? !!props.job?.cvMandatory : true}
                                inputRef={register()}
                                name="cvMandatory"
                            />}
                            label="חובה להגיש מועמדות עם קו''ח"
                        />
                    </FormGroup>
                </ShadowBGContainer>
                <CustomBR />
                <ShadowBGContainer>
                    <Typography color="secondary" variant="h6" fontWeight="bold">
                    בחירת תחומים ותפקידים למשרה
                    </Typography>
                    {jobTags?.length ? <>
                        <CustomBR />
                        <Typography color="secondary" variant="body1">
                        תחומים ותפקידים המשויכים למשרה:
                        </Typography>
                        <CustomBR h={10} />
                        {jobTags.map(tag => <RemovableTag
                            key={tag.tagid}
                            text={tag.tag}
                            onRemoveClick={() => setJobTags(jobTags.filter(t => t.tagid !== tag.tagid))}
                        />)}
                    </> : null}
                    <CustomBR />
                    <TagsSearchAutocomplete
                        filterItems={jobTags?.map(t => t.tag)}
                        clearOnChange
                        onChange={(ev, val: TagViewModel) => { setJobTags([...jobTags, val]); }} />
                    {filteredRecommnededTags.length > 0 ? <div>
                        <CustomBR h={10} />
                        <Typography color="secondary" variant="body1">הצעות לתגיות:</Typography>
                        <CustomBR h={10} />
                        {filteredRecommnededTags.map(tag => <AddableTag
                            key={`addable-${tag.tagid}`}
                            text={tag.tag}
                            onAddClick={() => { setJobTags([...jobTags, tag]); }}
                        />)}
                    </div> : ''}
                </ShadowBGContainer>
                <CustomBR />
                {!editMode && renderChooseJobPromotion()}
                <CustomBR />
                <Button size="large" onClick={handleSubmit(onSubmit)} color="primary" fullWidth>
                    {editMode ? 'שמירת פרטי המשרה' : 'פרסום משרה'}
                </Button>
                <CustomBR /></>}
        </Container>
        <CustomBR />
    </Page>;
};

export default UserPostJobPage;
