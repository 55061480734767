import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import './utils/polyfills';

declare global {
    interface Window {
        CURRENT_PAGE_COMPONENT: string
        APP_PROPS?: any
    }
}

Sentry.init({
    dsn: 'https://a25fe6f99eb4498aa4a7c27095899224@o4508326577111040.ingest.de.sentry.io/4508329091137616',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error', 'warn'],
        }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ReactComponent = require(`./pages/${window.CURRENT_PAGE_COMPONENT}.page`);
const container = document.getElementById('container');
const component = React.createElement(ReactComponent.default, window.APP_PROPS);

ReactDOM.hydrate(component, container);
