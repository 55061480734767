import {
    Box,
    Divider, Grid, Hidden, IconButton, Typography, useMediaQuery,
} from '@mui/material';
import Linkify from 'linkifyjs/react';
import {
    Favorite as FavoriteIcon, Business as BusinessIcon,
    EventAvailableOutlined as EventAvailableOutlinedIcon,
    LocationOnOutlined as LocationOnOutlinedIcon,
    FavoriteBorder as FavoriteBorderIcon,
    DeleteOutline as DeleteOutlineIcon,
    PhoneEnabledOutlined as PhoneEnabledOutlinedIcon,
} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {
    memo, useContext, useEffect, useState, useCallback,
} from 'react';
import styled from 'styled-components';

import { JobSearchResultViewModel } from '../../../../types/jobs.types';
import Anchor from '../../foundation/Anchor';
import AnchorTag from '../../foundation/AnchorTag';
import Button, { TextButton } from '../../foundation/Button';
import CustomBR from '../../foundation/CustomBR';
import theme from '../../layout/theme';
import DateFormat from '../../foundation/DateFormat';
import { CenteredFlex } from '../../foundation/Flex';
import user_to_job_stateEnum from '../../../../common/user_to_job_state.enum';
import UserContext from '../../context/user.context';
import PromotedTag from '../../foundation/PromotedTag';

export type JobItemProps = {
    job: JobSearchResultViewModel
    showFullDetails?: boolean
    onReportClick?: (job: JobSearchResultViewModel) => void,
    onApplyClick?: (job: JobSearchResultViewModel) => void,
    onRemoveJobClick?: (job: JobSearchResultViewModel) => void,
    onSaveJobClick?: (job: JobSearchResultViewModel) => void,
    onUnsaveJobClick?: (job: JobSearchResultViewModel) => void,
    onReadMoreClick?: (job: JobSearchResultViewModel) => void,
    lightMode?: boolean,
    showTags?: boolean
    showRemoveJob?: boolean
    showDetailsLink?: boolean
    showPhoneBtn?: boolean
}

const JobItemStyled = styled.div<{ promoted?: boolean }>`
    border-radius: 10px;
    box-shadow: 0 10px 36px 0 #eeeff1cc;
    border: solid 1px #d1dce4;
    background-color: ${props => (props.promoted ? props.theme.palette.primary.extraLight : '#ffffff')};
    ${props => (props.promoted && `border: solid 1px ${props.theme.palette.primary.light};`)};
    padding: 1.2rem;
    .MuiTypography-body1 {
        unicode-bidi: embed;
        white-space: pre-line;
        overflow-wrap: break-word;
    }
`;

const JobDetailsStyled = styled.div<{ align: 'left' | 'right' }>`
    direction: ${props => (props.align === 'left' ? 'ltr' : 'rtl')};
`;

const IconButtonStyled = styled(IconButton)`
    width: 65px;
    height: 60px;
    border-radius: 8px;
    background-color: #f9f9fb;
    cursor: pointer;
`;
const RedIconButtonStyled = styled(IconButton)`
    width: 65px;
    height: 60px;
    border-radius: 8px;
    background-color: #ffecec;
    cursor: pointer;
    svg {
        color: #ff4b4b;
    }
`;
const DateStyled = styled(Box)`
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9fb;
    color: ${props => props.theme.palette.secondary.main};
`;

const PhoneButtonStyled = styled(Box)`
    display: flex;
    margin-inline-start: 10px;
    .MuiButtonBase-root {
        background-color:#0291ef;
    }
`;
const ExtraDataStyle = styled(Box)`
    color: #8799a5;
    padding: 5px 0;
`;
const MAX_LENGTH_DETAILS = 150;

export type JobItemActionsProps = {
    job: JobSearchResultViewModel
    onApplyClick?: (job: JobSearchResultViewModel) => void,
    onRemoveJobClick?: (job: JobSearchResultViewModel) => void,
    onSaveJobClick?: (job: JobSearchResultViewModel) => void,
    onUnsaveJobClick?: (job: JobSearchResultViewModel) => void,
    onWhatsAppClick?: (job: JobSearchResultViewModel) => void,
    showRemoveJob?: boolean
    showDetailsLink?: boolean
}

const JobItemActions = memo(({
    onSaveJobClick,
    onUnsaveJobClick,
    job,
    onRemoveJobClick,
    showDetailsLink,
    showRemoveJob,
    onApplyClick,
    onWhatsAppClick,
}: JobItemActionsProps) => {
    const onContactUsClick = useCallback(() => onWhatsAppClick(job), [job]);
    const onApplyHandlerClick = useCallback(() => onApplyClick(job), [job]);

    return <Grid display="flex" direction="column" alignContent="center" item container md={3} xs={12}>
        <Box display="flex" justifyContent="space-between">
            {showRemoveJob && <IconButtonStyled title="לא מעוניין במשרה" onClick={() => onRemoveJobClick(job)}>
                <DeleteOutlineIcon color="secondary" />
            </IconButtonStyled>}
            {job.isUserSaved ? <RedIconButtonStyled title="הסר משרה מהמועדפים" onClick={() => onUnsaveJobClick(job)}>
                <FavoriteIcon />
            </RedIconButtonStyled> : <IconButtonStyled title="הוסף משרה למועדפים" onClick={() => onSaveJobClick(job)}>
                <FavoriteBorderIcon color="secondary" />
            </IconButtonStyled>}
        </Box>
        <CustomBR />
        {job.userToJobState === user_to_job_stateEnum.LIKE ? <DateStyled>
            <CenteredFlex>
                <EventAvailableOutlinedIcon />
                <Typography display="inline">&nbsp;הגשת מועמדות ב&nbsp;</Typography>
            </CenteredFlex>
            <CenteredFlex>
                <DateFormat fontWeight="bold" date={job.userToJobStateDate} />
            </CenteredFlex>
        </DateStyled> : <Button
            title="הגש מועמדות למשרה"
            size="large" onClick={onApplyHandlerClick} fullWidth color="primary">הגש מועמדות</Button>}
        <CustomBR />
        {job.phone?.startsWith('05') && <><Button
        // @ts-ignore
            target="_blank"
            onClick={onContactUsClick}
            title="צור קשר WhatsApp"
            size="large" fullWidth variant="outlined" color="primary">
            <Typography textTransform="initial" display="flex">
                <><WhatsAppIcon fontSize="medium" />&nbsp; שלח WhatsApp</>
            </Typography>
        </Button>
        <CustomBR /></>}
        {showDetailsLink
        && <Hidden smDown>
            <Button title="עמוד פרטי המשרה" size="large" onClick={ev => {
                ev.preventDefault();
                window.location.assign(`/job/details/${job.jobid}`);
            }} fullWidth color="secondary">פרטים</Button>
            <CustomBR />
        </Hidden>}
    </Grid>;
});

const JobItem = ({
    job, ...props
}: JobItemProps): JSX.Element => {
    const [showPhone, setShowPhone] = useState<boolean>(false);
    const [showFullDetails, setShowFullDetails] = useState<boolean>(props.showFullDetails);
    useEffect(
        () => { if (showFullDetails && props.onReadMoreClick) props.onReadMoreClick(job); },
        [showFullDetails],
    );
    let details = `${job.description}${job.requirement ? ` \n דרישות: \n ${job.requirement}` : ''}`;
    let readMore = '';
    const ltr = !!details.match(/^[a-zA-Z]/);

    if ((!showFullDetails || props.lightMode) && details.length > MAX_LENGTH_DETAILS) {
        details = `${details.slice(0, MAX_LENGTH_DETAILS)}... `;
        readMore = ltr ? 'read more' : 'קרא עוד';
    }

    const user = useContext(UserContext);

    const showNotLoggedInModal = () => {
        window.showGlobalModal(
            'אינך מחובר למערכת',
            'על מנת לצפות בפרטי הקשר של משרה זו, יש להירשם או להתחבר לאתר.',
            {
                footer: {
                    leftBtnText: 'הירשם',
                    leftBtnClick: () => {
                        window.modals.toggleRegisterModal();
                    },
                    rightBtnText: 'התחבר',
                    rightBtnClick: () => {
                        window.modals.toggleLoginModal();
                    },
                },
            },
        );
    };

    const onWhatsAppClick = useCallback(() => {
        if (!user && (job.promotion === undefined || job.promotion === null)) {
            showNotLoggedInModal();
            return;
        }

        window.open(`https://wa.me/${job.phone.replace('0', '+972')}?text=${encodeURIComponent(
            `שלום רב,
אשמח לשמוע פרטים נוספים על המשרה:

${job.description.slice(0, 200)}${job.description.length > 200 ? '...' : ''}

https://www.mploy.co.il/wa/jobs/${job.jobid}/candidate`)}`, '_blank');
    }, [job, showNotLoggedInModal, user]);

    const onPhoneClick = useCallback(() => {
        if (!user && (job.promotion === undefined || job.promotion === null)) {
            showNotLoggedInModal();
        } else if (showPhone) { window.location.assign(`tel:${job.phone}`); } else setShowPhone(true);
    }, [showPhone, job, user, setShowPhone, showNotLoggedInModal]);

    const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return <JobItemStyled data-jobid={job.jobid} promoted={!!job.promotion}>
        {user?.admin ? <><Box display="flex">
            <Typography color="red" fontWeight="bold">כלי ניהול:&nbsp;</Typography>
            <Anchor blank href={`/user/editJob/${job.jobid}`} text="עריכת משרה" />
        </Box><CustomBR /></> : ''}
        <Box display="flex">
            {!props.lightMode && <>
                <Hidden smDown>
                    {!!job.promotion && <PromotedTag text="מקודם" />}
                </Hidden>
                <DateStyled alignItems="center" display="inline-flex">
                    <EventAvailableOutlinedIcon />
                    <Hidden smDown>
                        <Typography display="inline">&nbsp;נכון לתאריך&nbsp;</Typography>
                    </Hidden>
                    <DateFormat fontWeight="bold" date={job.updated_at} />
                </DateStyled>
            </>}
            {props.showPhoneBtn && job.phone && <PhoneButtonStyled>
                <Button onClick={onPhoneClick} title={showPhone ? 'התקשרות' : 'הצג מספר טלפון'}>
                    <><PhoneEnabledOutlinedIcon /><Hidden smDown>&nbsp;{showPhone ? job.phone : 'הצג טלפון'}</Hidden></>
                </Button>
            </PhoneButtonStyled>}
            {!props.lightMode && <>
                <Box style={{ marginInlineStart: 'auto ' }}>
                    <TextButton size="small" onClick={() => props.onReportClick(job)} title="דווח למערכת על המשרה">
                        <Typography color="secondary" fontSize={24}>⚠</Typography>
                    </TextButton>
                </Box>
            </>}
        </Box>
        <CustomBR />
        <Grid container spacing={2}>
            <Grid item md={props.lightMode ? 12 : 8} xs={12}>
                <Box display="flex">
                    <Anchor color="secondary" variant="h4" fontWeight="bold" blank href={`/job/details/${job.jobid}`}>
                        {job.title}
                    </Anchor>
                    {!xSmallScreen && !props.lightMode && job.company?.logo
                    && <Box style={{ marginInlineStart: 'auto', paddingInlineStart: '5px' }}>
                        <img alt={job.company.name} width={60} src={`/assets/logos/${job.companyid.toString().split('').join('/')}/${job.company.logo}`} />
                    </Box>}
                </Box>
                <ExtraDataStyle alignItems="center" display="flex">
                    <LocationOnOutlinedIcon fontSize="small" color="secondary" />
                    <Typography display="inline">
                        &nbsp;{job.formatted_address}
                        {job.distance && <>{job.distance < 1 ? `, ${job.distance * 1000} מטרים ממיקומך` : `, ${job.distance} ק"מ ממיקומך`}</>}
                    </Typography>
                </ExtraDataStyle>
                {job.company && <ExtraDataStyle alignItems="center" display="flex">
                    <BusinessIcon fontSize="small" color="secondary" />
                    <Typography display="inline">&nbsp;{job.company.name}{job.company.hrCompany ? <span> (כח אדם)</span> : ''}</Typography>
                </ExtraDataStyle>}
                <CustomBR />
                <JobDetailsStyled align={ltr ? 'right' : 'left'}>
                    {readMore ? <Typography
                        key="read-more"
                        variant="body1">
                        <Linkify options={{ attributes: { rel: 'nofollow' } }}>
                            {details}
                        </Linkify>
                        <Anchor color={theme.palette.common.yellowText} href="#" text={readMore} onClick={ev => {
                            ev.preventDefault();
                            setShowFullDetails(true);
                        }} /></Typography> : <Typography key="no-read-more" variant="body1" align={ltr ? 'right' : 'left'}>
                        <Linkify options={{ attributes: { rel: 'nofollow' } }}>
                            {details}
                        </Linkify>
                    </Typography>}
                </JobDetailsStyled>
                <CustomBR />
                {props.showTags && !props.lightMode && job?.tags?.length && <Hidden smDown>
                    {job.tags.map(pt => <AnchorTag display={pt.display} key={pt.tag} tag={pt.tag} />)}
                </Hidden>}
            </Grid>
            {!props.lightMode && <>
                <Hidden smDown>
                    <Grid item md={1}>
                        <Divider orientation="vertical" />
                    </Grid>
                </Hidden>
                <JobItemActions
                    job={job}
                    onSaveJobClick={props.onSaveJobClick}
                    onUnsaveJobClick={props.onUnsaveJobClick}
                    onRemoveJobClick={props.onRemoveJobClick}
                    showDetailsLink={props.showDetailsLink}
                    showRemoveJob={props.showRemoveJob}
                    onApplyClick={props.onApplyClick}
                    onWhatsAppClick={onWhatsAppClick}
                />
            </>}
        </Grid>
    </JobItemStyled>;
};

JobItem.defaultProps = {
    showTags: true,
};

export default memo(JobItem);
