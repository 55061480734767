import React from 'react';
import styled from 'styled-components';

export type LogoProps = {
}

const LogoStyled = styled.div`
    img {
        width: 12rem;
        height: auto;
        ${props => props.theme.breakpoints.down('sm')} {
            width: 8rem;
        }
    }
`;
const LogoHandStyled = styled.div`
    img {
        width: 3rem;
    }
`;
const Logo = (): JSX.Element => <LogoStyled> <a href="/" title="Mploy - דרושים">
    <img alt="Mploy - דרושים"
        src="/images/logo.svg" />
</a></LogoStyled>;

export const LogoHand = (): JSX.Element => <LogoHandStyled><a href="/" title="Mploy - דרושים">
    <img alt="Mploy - דרושים"
        src="/images/hand.svg" />
</a></LogoHandStyled>;

export default Logo;
