import {
    Collapse,
} from '@mui/material';
import React from 'react';
import user_to_job_stateEnum from '../../../../common/user_to_job_state.enum';
import { LoggedInUser, UserProfileViewModel } from '../../../../types/auth.types';
import { JobSearchResultViewModel } from '../../../../types/jobs.types';
import {
    userApplyToJob, userDislikeJob, userSaveJob, userUnsaveJob,
} from '../../../api/userToJob.api';
import JobItem from '../../components/JobItem';
import CustomBR from '../../foundation/CustomBR';
import ApplyToJobMissingDataModal from '../../modals/ApplyToJobMissingDataModal';
import ApplyToJobModal from '../../modals/ApplyToJobModal';
import ReportJobModal from '../../modals/ReportJob';
import InListAd from '../../ads/InListAd';

export type JobsItemListProps = {
    user?: LoggedInUser,
    jobs: JobSearchResultViewModel[],
    userProfile?: UserProfileViewModel,
    onUserChange: (user: LoggedInUser) => void
    onJobsChange: (jobs: JobSearchResultViewModel[]) => void
    onReadMoreClick?: (jobs: JobSearchResultViewModel) => void
    onUserProfileChange: (userProfile: UserProfileViewModel) => void
    showFullDetails?: boolean
    showRemoveJob?: boolean
    showDetailsLink?: boolean
    showPhoneBtn?: boolean
    innerRef?: React.RefObject<HTMLDivElement>
    showTags?: boolean
    showAds?: boolean
}

type JobsPageState = {
    /**
     * the jobid to collapse in case the user removes it
     */
    removedJobs?: number[]
    showReportModal?: boolean,
    showApplyToJobModal?: boolean,
    showApplyToJobMissingDetailsModal?: boolean,
    /**
     * reprensts that job that is currently the context of the opened modal (report / apply to etc)
     */
    modalJobContext?: JobSearchResultViewModel
};

class JobItemList extends React.PureComponent<JobsItemListProps, JobsPageState> {
    static defaultProps = {
        showRemoveJob: true,
        showPhoneBtn: true,
        showDetailsLink: true,
        showAds: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            removedJobs: [],
            showReportModal: false,
            showApplyToJobModal: false,
            showApplyToJobMissingDetailsModal: false,
        };
    }

    removeJob = (job: JobSearchResultViewModel) => {
        if (this.props.user) {
            const removedJobs = [...this.state.removedJobs, job.jobid];
            this.setState({ removedJobs });
            userDislikeJob(job.jobid, this.props.user.userid);
        } else {
            window.showGlobalModal(
                'אינך מחובר למערכת',
                'על מנת לסמן משרות שאינך מעוניין בהם, יש להירשם או להתחבר לאתר.',
            );
        }
    };

    saveJob = (job: JobSearchResultViewModel) => {
        if (this.props.user) {
            userSaveJob(job.jobid, this.props.user.userid);
            this.props.onJobsChange(
                this.props.jobs.map(
                    j => (j.jobid === job.jobid ? { ...j, isUserSaved: 1 } : j),
                ),
            );
        } else {
            window.showGlobalModal(
                'אינך מחובר למערכת',
                'על מנת לשמור משרות, יש להירשם או להתחבר לאתר.',
            );
        }
    };

    unsaveJob = (job: JobSearchResultViewModel) => {
        userUnsaveJob(job.jobid, this.props.user.userid);
        this.props.onJobsChange(
            this.props.jobs.map(j => (j.jobid === job.jobid ? { ...j, isUserSaved: 0 } : j)),
        );
    };

    toggleReportModal = (job?: JobSearchResultViewModel) => {
        this.setState({
            modalJobContext: job,
            showReportModal: !this.state.showReportModal,
        });
    };

    closeApplyToJobModal = () => { this.setState({ showApplyToJobModal: false }); };

    closeApplyToJobMissingDataModal = () => { this.setState({ showApplyToJobMissingDetailsModal: false }); };

    applyBtnClick = job => {
        if (job.applyLink) {
            window.open(job.applyLink, '_blank');
            return;
        }

        const { userProfile, user } = this.props;
        this.setState({ modalJobContext: job });
        if (user && userProfile.cvs.length > 0 && userProfile.phone) {
            this.setState({ showApplyToJobModal: true });
        } else {
            this.setState({ showApplyToJobMissingDetailsModal: true });
        }
    };

    renderJobs() {
        return this.props.jobs?.map((j, index) => <Collapse key={j.jobid} in={this.state.removedJobs.indexOf(j.jobid) === -1}>
            <CustomBR />
            <JobItem
                showFullDetails={this.props.showFullDetails}
                onApplyClick={this.applyBtnClick}
                onReportClick={this.toggleReportModal}
                onSaveJobClick={this.saveJob}
                onUnsaveJobClick={this.unsaveJob}
                onRemoveJobClick={this.removeJob}
                job={j}
                showTags={this.props.showTags}
                showPhoneBtn={this.props.showPhoneBtn}
                showDetailsLink={this.props.showDetailsLink}
                showRemoveJob={this.props.showRemoveJob}
                onReadMoreClick={this.props.onReadMoreClick}
            />
            {this.props.showAds && ((index === 2 || index % 10 === 0) && index !== 0) && <><CustomBR /><InListAd key={index} /></>}
        </Collapse>);
    }

    apply = async (userProfile: UserProfileViewModel, job: JobSearchResultViewModel, data: {
        message: string;
        cvid: number;
    }) => {
        await userApplyToJob({ jobid: job.jobid, userid: userProfile.userid }, data, true);
        this.setState({ showApplyToJobModal: false });
        this.props.onJobsChange(
            this.props.jobs.map(
                j => (j.jobid === job.jobid ? {
                    ...j,
                    userToJobState: user_to_job_stateEnum.LIKE,
                    userToJobStateDate: (new Date()).toISOString(),
                } : j),
            ),
        );
    };

    updateUserProfile = (userProfile: UserProfileViewModel) => { this.props.onUserProfileChange(userProfile); };

    updateUser = (user: LoggedInUser) => { this.props.onUserChange(user); };

    render() {
        return <div ref={this.props.innerRef}>
            {this.props.user && this.props.userProfile && <ApplyToJobModal
                onApplyClick={this.apply}
                job={this.state.modalJobContext}
                userProfile={this.props.userProfile}
                onClose={this.closeApplyToJobModal}
                open={this.state.showApplyToJobModal} />}
            <ApplyToJobMissingDataModal
                onApplyClick={this.apply}
                onRegistrationComplete={this.updateUser}
                onUserProfileUpdate={this.updateUserProfile}
                onClose={this.closeApplyToJobMissingDataModal}
                user={this.props.user}
                userProfile={this.props.userProfile}
                job={this.state.modalJobContext}
                open={this.state.showApplyToJobMissingDetailsModal} />
            <ReportJobModal job={this.state.modalJobContext}
                onClose={this.toggleReportModal} open={this.state.showReportModal} />
            {this.renderJobs()}
            <CustomBR />
        </div>;
    }
}

export default JobItemList;
