import React from 'react';
import {
    AutocompleteProps, Autocomplete, CircularProgress, Typography,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Search, MyLocation, Business } from '@mui/icons-material';

import TextField from '../../foundation/TextField';
import newAPIService from '../../../api/newAPIService';
import { TagViewModel } from '../../../../types/tags.types';
import { CompanyViewModel } from '../../../../mappers/companyMapper';

export type AutocompleteOption = { id: string, name: string };

type City = {
    city: string
    cityid: number
    countryid: number
    created_at: string
    latitude: number
    longitude: number
    updated_at: string
    variation1?: string
    variation2?: string
    variation3?: string
    variation4?: string
};
type Area = {
    address: string
    areaid: number
    countryid: number
    created_at: string
    latitude: number
    longitude: number
    updated_at: string
    variation1?: string
    variation2?: string
    variation3?: string
    variation4?: string
}
type CitiesAPIModel = (City | Area)[]

export type AutocompleteRemoteProps = {
    url?: string,
    prefetchUrl?: string,
    label: string
    mapPrefetchData?: (data: any) => AutocompleteOption
    mapData: (data: any) => AutocompleteOption
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void
    renderOption?: (optionProps: any, option: any) => JSX.Element
    renderEndAdornment: () => JSX.Element
    onEnter?: (ev: React.KeyboardEvent<HTMLDivElement>) => void
    helperText?: string
    clearOnChange?: boolean
    filterItems?: string[],
    required: boolean
} & Partial<AutocompleteProps<any, any, any, any>>

type State = {
    open: boolean,
    prefetchList?: AutocompleteOption[]
    options?: AutocompleteOption[],
    cache?: { [key: string]: AutocompleteOption[] },
    value?: AutocompleteOption
    inputValue?: string,
    loading?: boolean
}

class AutocompleteRemote extends React.Component<AutocompleteRemoteProps, State> {
    componentDidMount() {
        this.init();
    }

    state = {
        open: false,
        loading: false,
        cache: {},
        inputValue: this.props.defaultValue || '',
    } as State;

    init = () => {
        this.prefetchData();
    };

    componentWillUnmount() {
        this.fetchData.clear();
    }

    prefetchData = async () => {
        if (!this.props.prefetchUrl) return;
        const response = await newAPIService.get<CitiesAPIModel>(this.props.prefetchUrl);
        const list = response.data.map(this.props.mapPrefetchData);
        this.setState({ prefetchList: list, options: list });
    };

    fetchData = debounce(() => {
        this.setState({ loading: true }, async () => {
            const { inputValue, loading, cache } = this.state;
            const response = await newAPIService.get<any>(`${this.props.url}?contains=${encodeURIComponent(inputValue)}`);
            // in case we got results from different source (prefetch for e.g)
            if (!loading || !response.data) return;
            if (!response.data.results?.map && !response.data.map) {
                console.error('api data is in wrong format. make sure its an array or has results prop array');
                return;
            }
            const newCache = { ...cache };
            const options = (response.data.results || response.data).map(res => {
                const option = this.props.mapData(res);
                newCache[option.name] = [option];
                return option;
            });
            newCache[inputValue.trim()] = options;
            this.setState({ cache: newCache, options, loading: false });
        });
    }, 400);

    filter = () => {
        const {
            inputValue, prefetchList, cache,
        } = this.state;
        if (!inputValue) {
            this.setState({ options: prefetchList });
            return;
        }
        let items = cache[inputValue.trim()] || [];
        if (prefetchList) {
            items = [...items, ...prefetchList.filter(i => i.name.indexOf(inputValue) !== -1)];
        }
        if (items.length) {
            this.fetchData.clear();
            this.setState({ options: items, loading: false });
        } else this.fetchData();
    };

    onInputChange = (event: React.SyntheticEvent<Element, Event>, newInputValue: string, reason) => {
        if (newInputValue.trim() !== this.state.inputValue) {
            this.setState({ inputValue: newInputValue }, this.filter);
        }
        if (this.props.onInputChange) this.props.onInputChange(event, newInputValue, reason);
    };

    renderOption = (optionProps, option) => <li key={option.id} {...optionProps}>
        <Typography variant="body2">{option.name}</Typography>
    </li>;

    renderInput = params => <TextField
        {...params}
        helperText={this.props.helperText}
        label={this.props.label}
        required={this.props.required}
        InputProps={{
            ...params.InputProps,
            endAdornment: this.state.loading ? <CircularProgress size={20} /> : this.props.renderEndAdornment(),
        }}
    />;

    onChange = (event, newValue) => {
        if (this.props.onChange) this.props.onChange(event, newValue);
        if (this.props.clearOnChange) this.setState({ inputValue: '' });
    };

    onOpen = () => { this.setState({ open: true }); };

    onClose = () => { this.setState({ open: false }); };

    getOptionLabel = option => (typeof option === 'string' ? option : option.name);

    filterOptions = x => x;

    onKeyUp = (ev: React.KeyboardEvent<HTMLDivElement>) => {
        if (ev.key === 'Enter' && this.props.onEnter) this.props.onEnter(ev);
    };

    render() {
        const {
            open, loading, options = [],
        } = this.state;

        const { filterItems } = this.props;

        return <Autocomplete
            open={open}
            value={this.state.inputValue}
            onOpen={this.onOpen}
            onClose={this.onClose}
            freeSolo
            filterOptions={this.filterOptions}
            autoComplete
            filterSelectedOptions
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            getOptionLabel={this.getOptionLabel}
            options={filterItems?.length ? options.filter(opt => filterItems?.indexOf(opt.name) === -1) : options}
            disableClearable
            loading={loading}
            size={this.props.size}
            disabled={this.props.disabled}
            loadingText="טוען..."
            noOptionsText="אין תוצאות"
            onKeyUp={this.onKeyUp}
            renderInput={this.renderInput}
            renderOption={this.props.renderOption || this.renderOption}
            clearOnBlur={this.props.clearOnBlur}
            blurOnSelect={this.props.blurOnSelect}
        />;
    }
}

export default AutocompleteRemote;

export type LocationOption = {
    name?: string,
    id?: string,
    formatted_address: string,
    latitude: number,
    longitude: number,
    areaid?: number
    cityid?: number
    [key: string]: any;
}

type GeocodeAPIModel = {
    address_components: any,
    formatted_address: string,
    geometry: {
        location: {
            lat: number,
            lng: number
        },
        viewport: any,
    }
    place_id: string,
}

export type GeoAutocompleteProps = {
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: LocationOption) => void
    helperText?: string
    required?: boolean
} & Partial<AutocompleteProps<any, any, any, any>>

export class GeoAutocomplete extends React.PureComponent<GeoAutocompleteProps> {
    mapPrefetchData = (loc: City | Area) => ({
        cityid: (loc as City).cityid,
        areaid: (loc as Area).areaid,
        name: (loc as City).city || (loc as Area).address,
        formatted_address: (loc as City).city || (loc as Area).address,
        id: loc.latitude.toString(),
        latitude: loc.latitude,
        longitude: loc.longitude,
    });

    mapData = (data: GeocodeAPIModel): LocationOption => ({
        name: data.formatted_address,
        id: data.place_id,
        geo_placeid: data.place_id,
        formatted_address: data.formatted_address,
        latitude: data.geometry.location.lat,
        longitude: data.geometry.location.lng,
    });

    renderEndAdornment = () => <MyLocation />;

    render() {
        return <AutocompleteRemote
            required={this.props.required}
            disabled={this.props.disabled}
            defaultValue={this.props.defaultValue}
            mapPrefetchData={this.mapPrefetchData}
            mapData={this.mapData}
            url="/geocode"
            prefetchUrl="/cities"
            label="איפה"
            size={this.props.size}
            helperText={this.props.helperText}
            renderEndAdornment={this.renderEndAdornment}
            onChange={this.props.onChange}
        />;
    }
}

export type CompanyAutocompleteProps = {
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: LocationOption) => void
    helperText?: string
} & Partial<AutocompleteProps<any, any, any, any>>

export class CompanyAutocomplete extends React.PureComponent<GeoAutocompleteProps> {
    mapData = (company: CompanyViewModel): CompanyViewModel & { id: string } => ({
        ...company,
        id: company.companyid.toString(),
    });

    renderEndAdornment = () => <Business />;

    render() {
        return <AutocompleteRemote
            disabled={this.props.disabled}
            defaultValue={this.props.defaultValue}
            onInputChange={this.props.onInputChange}
            mapData={this.mapData}
            url="/companies"
            label="שם החברה"
            size={this.props.size}
            helperText={this.props.helperText}
            renderEndAdornment={this.renderEndAdornment}
            onChange={this.props.onChange}
        />;
    }
}

export type TagsSearchAutocompleteProps = {
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void
    onEnter?: (ev: React.KeyboardEvent<HTMLDivElement>) => void
    clearOnChange?: boolean
    filterItems?: string[],
} & Partial<AutocompleteProps<any, any, any, any>>

export class TagsSearchAutocomplete extends React.Component<TagsSearchAutocompleteProps> {
    val: string;

    mapData = (data: TagViewModel): AutocompleteOption => ({
        name: data.tag,
        id: data.tagid.toString(),
        ...data,
    });

    renderEndAdornment = () => <Search />;

    renderOption = (optionProps, option: TagViewModel & AutocompleteOption) => {
        let type: string;
        if (option.level === 1) type = 'תחום';
        if (option.level === 2) type = 'תפקיד';
        if (option.vertical === true) type = 'סוג משרה';
        const parts = option.name.split(this.val);
        return <li key={option.id} {...optionProps}>
            <Typography variant="body1">
                {parts.map(
                    (part, i) => <span key={i + part}>{part}{i < (parts.length - 1) && <b>{this.val}</b>}</span>,
                )}{<b> ({type})</b>}
            </Typography>
        </li>;
    };

    onInputChange = (ev, val, reason) => {
        this.val = val;
        if (this.props.onInputChange) this.props.onInputChange(ev, val, reason);
    };

    render() {
        return <AutocompleteRemote
            onEnter={this.props.onEnter}
            size={this.props.size}
            onInputChange={this.onInputChange}
            renderOption={this.renderOption}
            renderEndAdornment={this.renderEndAdornment}
            mapData={this.mapData}
            url="/tags"
            clearOnBlur={this.props.clearOnBlur}
            blurOnSelect={this.props.blurOnSelect}
            label="בחר תחומים, תפקידים או טקסט"
            onChange={this.props.onChange}
            filterItems={this.props.filterItems}
            clearOnChange={this.props.clearOnChange}
            defaultValue={this.props.defaultValue}
        />;
    }
}
